.bg-wrapper {
  text-align: center;
  background-color: #f7f7f7;
  height: 100%;
}
.login-form {
  width: 30em;
  max-width: 90%;
  background-color: white;
  padding: 2em 4em;
  margin: auto;
  border-radius: 0.8em;
  box-shadow: 0px 0px 15px #00000007;
}
.login-form label {
  font-weight: bold;
}

.ant-layout-sider-children {
  background-color: 'red'!important;
}

.mobile-only {
  display: none;
}
.desktop-only {
  display: unset;
}

.player {
  background-color: #f0f0f0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
}

.player-progress {
  width: 0;
  height: 100%;
  background-color: #007bff;
}
.small-ic {
  width: 20px;
  height: 20px;
}
.p-no-margin > p {
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .mobile-only {
    display: unset;
  }
  .desktop-only {
    display: none;
  }
  .login-form {
    padding: 20px;
  }
  .venue-panel {
    max-height: 45vh;
  }
}

.ant-layout-header.ant-pro-layout-header-fixed-header {
  z-index: 500!important;
}
.ant-descriptions-row > th.ant-descriptions-item, .ant-descriptions-row > td.ant-descriptions-item {
  padding-bottom: 0px!important;
}

.full-content-height {
  height: calc(100vh - 40px);
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.circle-num {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background: rgba(85, 108, 148, 0.3);
  vertical-align: -25%;
}
.square-bg {
  background-size: contain;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjcuNSIgeT0iNy41IiB3aWR0aD0iODUiIGhlaWdodD0iODUiIHN0cm9rZT0iI2FhYSIvPgo8bGluZSB4MT0iNDkuNSIgeTE9IjciIHgyPSI0OS41IiBzdHJva2U9IiNhYWEiLz4KPGxpbmUgeDE9IjQ5LjUiIHkxPSIxMDAiIHgyPSI0OS41IiB5Mj0iOTMiIHN0cm9rZT0iI2FhYSIvPgo8bGluZSB4MT0iOTMiIHkxPSI0OS41IiB4Mj0iMTAwIiB5Mj0iNDkuNSIgc3Ryb2tlPSIjYWFhIi8+CjxsaW5lIHgxPSIyLjE4NTU3ZS0wOCIgeTE9IjQ5LjUiIHgyPSI3IiB5Mj0iNDkuNSIgc3Ryb2tlPSIjYWFhIi8+Cjwvc3ZnPgo=')
}
.collapse-offset-tool >.ant-collapse-item > .ant-collapse-header {
  padding: 4px 12px;
}

.ic-group {
  margin: 0;
  padding: 0;
  list-style: none;
  top: 0;
  left: 0px;
  display: block;
  background: url('../assets/ic_group.png');
  background-size: auto 48px;
  width: 48px;
  height: 48px;
}
.cursor-pointer {
  cursor: pointer;
}
.tr-error-row, .ble-table tboday tr.tr-error-row  {
  background-color: #f99e9e80;
}
.tr-hover-row, .ble-table tboday tr.tr-hover-row {
  background-color: #bae0ff80;
}
.opacity-05 {
  opacity: 0.5;
}
.no-margin {
  margin: 0px !important;
  padding: 0px !important;
}
.alert-msg-no-padding .ant-alert-message, .mb0 {
  margin-bottom: 0px;
}
div.small-ul-wrapper > ul {
  margin: 0.5em 0px;
}
div.input-group > label {
  line-height: 24px;
}

.survey-tree .tree-title {
  line-height: 2rem;
}
.survey-tree .ant-typography-secondary > i {
  font-size: 70%;
}

.map-toolbar {
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: 0.4rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.map-center-toolbar {
  z-index: 400;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}
.legend--tag-group > .ant-tag {
  border-radius: 10rem;
}
.v-wrapper > .ant-page-header .ant-page-header-content {
  padding-block-start: 6px;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.venue-panel {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  min-width: 20vw
}
.text-08 {
  font-size: 80%;
  line-height: 12px;
}
.small-remark {
  font-size: 80%;
  font-family: Menlo, monospace, 'Courier New', Courier;
  color: rgba(0, 0, 0, 0.5);
}
.warn-row::after {
  content: '⚠';
  color: #faad14;
}
.tiny-table th, .tiny-table td {
  padding: 2px !important;
}
.collapse-btn-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  transition: all .2s;
}
.collapse-btn-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.ble-table {
  table-layout: 'fixed';
  border-collapse: 'separate';
  border-spacing: 0;
}
.ble-table tr {
  transition: background 0.2s, border-color 0.2s;
}
.ble-table tbody tr:hover {
  background-color: #bae0ff80;
}
.ble-table td, .ble-table th {
  overflow-x: hidden;
  border-bottom: 1px solid #f0f0f0;
}
.input-error-border {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f !important;
}
.tiny-row .ant-form-item, .tiny-row .ant-pro-form-list-action {
  margin-bottom: 12px;
}

.tiny-row .ant-list-item {
  padding: 2px 12px;
}

.tiny-checked-card .ant-pro-checkcard-content {
  padding: 8px 12px;
}

.single-line-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s linear;
}
.overlay-hint {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  z-index: 2;
}
.overlay-hint table tr td:first-child {
  text-align: right;
  font-weight: bold;
}
.overlay-hint table tr td:last-child {
  padding-left: 12px;
  text-align: left;
}
.overlay-hint a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
}
.full-weight-tab .ant-tabs-nav {
  width: 100% !important;
}
  
.full-weight-tab .ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
}
  
.full-weight-tab .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.ant-space-compact > .input-group {
  flex: 1
}

.hidden {
  display: none;
  visibility: hidden;
}
.gradient-1 {
  background: linear-gradient(45deg,#d9ffff,  #b7ffda);
}
.gradient-2 {
  background: linear-gradient(45deg, #09cc66, #03c7c5);
}
.color-success {
  color: #488d00;
}
.color-error {
  color: #ff4d4f;
}

#tier-btn-wrapper {
  position: absolute;
  left: 12px;
  bottom: 36px;
  border: 1px solid lightgrey;
  border-radius: 6px;
  background-color: #ffffffca;
}
#tier-btn-wrapper #tier-btn-body {
  display: none;
}
#tier-btn-wrapper:hover #tier-btn-icon {
  display: none;
}
#tier-btn-wrapper:hover #tier-btn-body {
  display: block;
}
.underline-red-wavy {
  text-decoration: red wavy underline;
}
.underline-orange-wavy {
  text-decoration: orange wavy underline;
}
.selected-row {
  box-sizing: border-box;
  background-color: #e6f4ff !important;
  border-left: #007bff 4px solid;
  font-weight: bold;
}
.cursor-pointer:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.desc-table {
  padding: 6px 0px;
}
.desc-table tr > td:first-child {
  padding: 3px 12px;
  vertical-align: top;
}
.desc-table tr:hover {
  background-color: #f7f7f7;
}
.desc-table td > span.anticon, .grey-icon {
  color: gray;
  font-size: 16px;
}
.rounded-tag {
  border-radius: 20px;
}

table.basic-table, table.basic-table td, table.basic-table th {
  border: 1px #dddddd solid;
}

table.basic-table th {
  background-color: #eeeeee;
}

table.basic-table {
  width: 100%;
  border-collapse: collapse;
}
.calendar-btn {
  visibility: hidden;
  display: flex;
  align-items: center;
}
.calendar-wrapper:hover .calendar-btn {
  visibility: visible;
}
#graph-tooltips {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  pointer-events: none;
  display: none;
}
.input-bordered {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
}